// ajaxRestForm jQuery Plugin
// This is a wrapper on top of $.ajaxRest, to provide an abstracted experience for the end-developer when dealing with standard ajax forms.
// It comes with a full set of callbacks that can communicate with the backend of the codebase consistently.
(function($, window, document) {
  $.fn.ajaxRestForm = function() {
    let options = {};
    let delegateFormSelector = null;

    switch (arguments.length) {
      case 2: // Delegate binding
        delegateFormSelector = arguments[0];
        options = arguments[1];
        break;
      default:
        // Element binding
        options = arguments[0];
    }

    options = $.extend(
      {
        RESPONSES_BY_STATUS_CODES: {}, // pass in variable "RESPONSES_BY_STATUS_CODES"
        timeout: 0,
        callbacks: {
          // Before AJAX gets sent out
          beforeSend: null,
          complete: null,

          // Connectivity error callbacks
          userAborted: null,
          responseTimedOut: null,

          // Parser error callbacks
          parserError: null,

          // Proper response callbacks
          success: null,
          requestMalformed: null,
          loginRequired: null,
          notEnoughAccess: null,
          notFound: null,
          unsupportedFormat: null,
          validationError: null,
          tooManyRequests: null,
          unexpectedError: null,

          // Default response callbacks (for error codes not handled
          default: null,
        },
      },
      options,
    );

    this.each(function() {
      if (delegateFormSelector === null) {
        var $form = $(this),
          method = $form.data('method') || $form.attr('method'),
          action = $form.attr('action');

        $form.submit(function(e) {
          e.preventDefault();

          if ($form.hasClass('is-request-pending')) {
            return false;
          }

          $form.addClass('is-request-pending');

          let promise = null;
          if (!$.isFunction(options.callbacks['beforeSend'])) {
            promise = Promise.resolve();
          } else {
            promise = new Promise(function(resolve, reject) {
              options.callbacks['beforeSend'].apply($form, [resolve, reject]);
            });
          }

          promise
            .then(function(value) {
              console.log('promise fulfilled with value: ', value);
              $form.find('.field.error').removeClass('error');
              $.ajaxRest({
                RESPONSES_BY_STATUS_CODES: options.RESPONSES_BY_STATUS_CODES,
                context: $form,
                method: method,
                url: action,
                timeout: options.timeout,
                data: $form.serialize(),
                callbacks: options.callbacks,
              })
              .always(function () {
                $form.removeClass('is-request-pending');
              });
            })
            .catch(function(reason) {
              $form.removeClass('is-request-pending');
              console.log('promise rejected with reason: ', reason);
            });
        });
      } else {
        $(this).on('submit', delegateFormSelector, function(e) {
          e.preventDefault();

          var $form = $(this),
            method = $form.data('method') || $form.attr('method'),
            action = $form.attr('action');

          if ($form.hasClass('is-request-pending')) {
            return false;
          }

          $form.addClass('is-request-pending');

          let promise = null;
          if (!$.isFunction(options.callbacks['beforeSend'])) {
            promise = Promise.resolve();
          } else {
            promise = new Promise(function(resolve, reject) {
              options.callbacks['beforeSend'].apply($form, [resolve, reject]);
            });
          }

          promise
            .then(
              function(value) {
                console.log('promise fulfilled');
                $form.find('.field.error').removeClass('error');
                $.ajaxRest({
                  RESPONSES_BY_STATUS_CODES: options.RESPONSES_BY_STATUS_CODES,
                  context: $form,
                  method: method,
                  url: action,
                  timeout: options.timeout,
                  data: $form.serialize(),
                  callbacks: options.callbacks,
                })
                .always(function () {
                  $form.removeClass('is-request-pending');
                });
              },
              function(reason) {
                $form.removeClass('is-request-pending');
                console.log('promise rejected2', reason);
              },
            )
            .catch(function(error) {
              $form.removeClass('is-request-pending');
              console.log('error');
            });
        });
      }
    });

    return this;
  };
})(jQuery, window, document);
