// ajaxRest jQuery Plugin
// All this plugin does is to create a wrapper on top of $.ajax, and manipulates the errors and their callbacks differently.  The reason we need this wrapper is because
// RESTful APIs make use of HTTP status codes such as 422 for validation errors.  $.ajax treats 422 as an error, while RESTful API expects this to be a common routine.
// This plugin separates out the "true" errors and normal callbacks and creates a new interface for the user to pass in callbacks.
(function($, window, document) {
  // http://stackoverflow.com/questions/417142/what-is-the-maximum-length-of-a-url-in-different-browsers
  var HTTP_REQUEST_URI_MAX_LENGTH = 2000;

  var HTTP_SERIES_2XX = 2,
    HTTP_SERIES_3XX = 3,
    HTTP_SERIES_4XX = 4,
    HTTP_SERIES_5XX = 5;

  $.ajaxRest = function(options) {
    options = $.extend(
      {
        RESPONSES_BY_STATUS_CODES: {}, // pass in variable "RESPONSES_BY_STATUS_CODES"
        context: this,
        headers: {},
        method: 'GET',
        url: null,
        data: {},
        timeout: 0,
        callbacks: {
          complete: null,

          // Connectivity error callbacks
          userAborted: null,
          responseTimedOut: null,

          // Parser error callbacks
          parserError: null,

          // Proper response callbacks
          success: null,
          requestMalformed: null,
          loginRequired: null,
          notEnoughAccess: null,
          notFound: null,
          unsupportedFormat: null,
          validationError: null,
          tooManyRequests: null,
          unexpectedError: null,

          // Default response callbacks (for error codes not handled above)
          default: null,
        },
      },
      options,
    );

    var helpers = {
      callback: function(response, jqXHR) {
        if (!$.isFunction(options.callbacks[response])) {
          return;
        }
        return options.callbacks[response].call(this, jqXHR);
      },
    };

    var method = options.method.toUpperCase(),
      RESPONSES_BY_STATUS_CODES = options.RESPONSES_BY_STATUS_CODES[method];

    // If the method is GET or DELETE, we have to watch out for the URI length limitation.  If it is over, need to spoof request.
    if (
      (method === 'GET' || method === 'DELETE') &&
      (options.url + '?' + options.data).length >= HTTP_REQUEST_URI_MAX_LENGTH
    ) {
      options.headers = $.extend(
        {
          'X-HTTP-Method-Override': method,
        },
        options.headers,
      );
      method = 'POST';
    }

    // If request method is REALLY a DELETE, move the data to the query string
    if (method === 'DELETE') {
      // Convert data if not already a string (copied from jquery.js)
      if (options.data && typeof options.data !== 'string') {
        options.data = $.param(options.data);
      }

      // Append options.data as a query string to the URL if it exists (follows $.ajax behavior similar to GET)
      if (options.data) {
        options.url = options.url.split(/[?#]/)[0] + '?' + options.data;
        options.data = {};
      }
    }

    return $.ajax({
      context: options.context,
      url: options.url,
      dataType: 'json',
      headers: options.headers,
      method: method,
      data: options.data,
      timeout: options.timeout,
      complete: function(jqXHR, textStatus) {
        // console.log('[AJAX COMPLETE] jqXHR: ', jqXHR);
        // console.log('[AJAX COMPLETE] textStatus: ', textStatus);

        // Connectivity errors / physical errors
        if (textStatus == 'abort') {
          // TODO: Log
          response = 'userAborted';
          helpers.callback.call(this, response, jqXHR);
          helpers.callback.call(this, 'complete', jqXHR);
          return;
        }

        if (textStatus == 'timeout') {
          // TODO: Log
          response = 'responseTimedOut';
          helpers.callback.call(this, response, jqXHR);
          helpers.callback.call(this, 'complete', jqXHR);
          return;
        }

        // Response received.  Error handling
        var statusCode = jqXHR.status,
          statusCodeSeries = Math.floor(Number(statusCode) / 100);

        if (statusCodeSeries === HTTP_SERIES_5XX) {
          // TODO: Log
          response = 'unexpectedError';
          helpers.callback.call(this, response, jqXHR);
          helpers.callback.call(this, 'complete', jqXHR);
          return;
        }

        // 4xx series errors with no parsable JSON response should result in parser error
        if (statusCodeSeries === HTTP_SERIES_4XX && !jqXHR.responseJSON) {
          textStatus = 'parsererror';
        }

        if (textStatus == 'parsererror') {
          // TODO: Log
          response = 'parserError';
          helpers.callback.call(this, response, jqXHR);
          helpers.callback.call(this, 'complete', jqXHR);
          return;
        }

        // We have a proper response for handling and callback
        var response = RESPONSES_BY_STATUS_CODES[statusCode];

        if (typeof response === 'undefined') {
          response = 'default';
        }

        helpers.callback.call(this, response, jqXHR);
        helpers.callback.call(this, 'complete', jqXHR);
        return;
      },
    });
  };
})(jQuery, window, document);
